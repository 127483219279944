import './vanillajs/editor.js';
import './vanillajs/edwiser.js';
import './vanillajs/lazy-load-video.js';
import './vanillajs/shop.js';
import './vanillajs/product-page.js';
import './vanillajs/all-courses.js';
import './vanillajs/how-it-works.js';
import './vanillajs/articles-filter.js';

// import './jquery/courses-filter.js';
