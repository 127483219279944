// document.addEventListener("DOMContentLoaded", function() {
//   // Check if the current URL contains 'user-account'
//   if (window.location.href.includes('user-account')) {
//       // Check if there is a <nav> element with the class 'eb-user-account-navigation-link'
//       const loginForm = document.querySelector('.edwiser-bridge > #user_login');
//       // If login does not exist then the user is logged in so display the btn
//       if (!loginForm) {
//           // Replace 'your-element-selector' with the actual selector of the element you want to display
//           const targetElement = document.querySelector('.enroll-students-btn');
//           if (targetElement) {
//               targetElement.style.display = 'block';
//           }
//       }
//   }
// });
