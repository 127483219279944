const categories = {
  'construction-safety': 'Construction',
  'dangerous-substances': 'COSHH',
  'falls': 'Falls',
  'featured': 'Featured',
  'first-aid': 'First Aid',
  'food': 'Food',
  'harassment': 'Harassment',
  'healthcare': 'Healthcare',
  'health-and-safety-law': 'Health & Safety Law',
  'office-safety': 'Office Safety',
  'risk-assessment': 'Risk Assessment',
  'safety-leadership': 'Safety Leadership',
  'learning-management': 'Training Staff',
  'transportation': 'Transportation',
  'wellbeing': 'Wellbeing',
  'young-people': 'Young People',
};

// Check if '/news' is in the URL path
if (window.location.pathname.includes('/news')) {
  const queryParams = new URLSearchParams(window.location.search);

  if (queryParams.has('category')) {
    const category = queryParams.get('category');
    setSelectedCategory(category);
  } else {
    assignTitle(null);
  }
}

// Add event listener to `li` elements
document.addEventListener('DOMContentLoaded', function () {
  const categoryItems = document.querySelectorAll('.article-categories-container li');

  categoryItems.forEach(item => {
    item.addEventListener('click', function (e) {
      e.preventDefault(); // Prevent default behavior

      const anchor = this.querySelector('a'); // Find the link inside the clicked `li`
      if (anchor) {
        const categoryElement = anchor.getAttribute('href');
        const category = categoryElement.split('/category/')[1].replace('/', '');

        setSelectedCategory(category);

        // Make an AJAX request
        fetch(ajax_obj.ajax_url, {
          method: 'POST',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          body: new URLSearchParams({
            action: 'filter_articles',
            category: category
          }),
        })
          .then(response => response.text())
          .then(responseHTML => {
            const container = document.querySelector('.echo-3-posts-container ul');
            container.innerHTML = responseHTML;
          })
          .catch(() => {
            const container = document.querySelector('.echo-3-posts-container ul');
            container.innerHTML = '<p>There was an error. Please try again later.</p>';
          });

        // Show loading message
        const container = document.querySelector('.echo-3-posts-container ul');
        container.innerHTML = '<p>Loading articles...</p>';
      }
    });
  });
});

// Function to assign the page title
function assignTitle(urlKeyWhichUserIsOn) {
  const title = urlKeyWhichUserIsOn ? categories[urlKeyWhichUserIsOn] : 'Health & Safety Articles';
  const titleElement = document.querySelector('.blog-page-all-articles-container h2');

  if (titleElement) {
    titleElement.textContent = title;
    titleElement.style.visibility = 'visible';
  }
}

// Function to set classes on elements
function setElementClasses(element, cssText) {
  element.className = cssText;
}

// Function to set the selected category
function setSelectedCategory(category) {
  const urlKeyWhichUserIsOn = Object.keys(categories).find(item => category.includes(item));
  assignTitle(urlKeyWhichUserIsOn);

  const categoriesHtmlList = document.querySelectorAll('.article-categories-container li');
  const categoriesHtmlListArray = Array.from(categoriesHtmlList);

  if (categoriesHtmlListArray.length > 0) {
    const selectedCategory = categoriesHtmlListArray.find(listItem => {
      const anchor = listItem.querySelector('a');
      return anchor && anchor.getAttribute('href').includes(category);
    });

    // Reset all items
    categoriesHtmlListArray.forEach(listItem => {
      setElementClasses(listItem, 'cat-item');
    });

    // Highlight the selected category
    if (selectedCategory) {
      setElementClasses(selectedCategory, 'cat-item background-secondary');
    }
  }
}
